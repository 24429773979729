import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from "./environments/environment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ImageProcessingService {

  constructor(private http: HttpClient) { }

  public processCarImage(formData: FormData): Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    let url = location.origin.toString() + '/process_car_image';

    return this.http.post( url, formData, {
      headers: headers,
      responseType: 'json'
    });
  }
}
