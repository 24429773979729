<div class="col-12 mt-5 row">

  <div class="col-12">
    <label for="formFileLg" class="form-label">Select an image (jpg or png):</label>
    <input class="form-control form-control-lg" id="formFileLg" type="file" accept="image/png, image/gif, image/jpeg" (change)="selectImage($event)">
  </div>

  <div class="col-12 row">

    <div *ngIf="!isLoading" class="col-md-2 col-sm-12 mt-3">
      <button type="button" class="btn btn-primary" [disabled]="!hexImage" style="width:100%" (click)="onSubmit()">Process</button>
    </div>

    <!-- <div class="col-2">
      <button type="button" class="btn btn-outline-primary" style="width:100%" (click)="cancel()">Cancel</button>
    </div> -->

    <div *ngIf="isLoading" class="col-md-2 col-sm-12 mt-3">
      <button type="button" disabled class="btn btn-primary" style="width:100%">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Loading...
      </button>
    </div>

    <div *ngIf="imageUrls && imageUrls.length > 0 && !isLoading" class="col-md-2 col-sm-12 mt-3">
      <button type="button" class="btn btn-success" style="width:100%" (click)="downloadImages()">Download</button>
    </div>
    
  </div>

  <p *ngIf="processError" class="mt-3" style="color: red">Oops: something went wrong. Please, try again</p>

  <div *ngIf="imageUrls && imageUrls.length > 0" style="background-color: #f8f9fa; border: 1px solid #dee2e6; border-radius: 5px" class="col-md-12 row mt-5">  
    <div class="col-md-3 mt-5 mb-5" *ngFor="let image of imageUrls; index as i">
      <div class="card"(click)="openModal(i)">
        <img class="card-img-top" [src]="image"
           alt="User Image" style="width: 100%; height: 250px; object-fit: cover;">
      </div>
    </div>
  </div>
  
</div>