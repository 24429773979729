import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ImageProcessingService} from "../image-processing.service";
import * as JSZip from 'jszip';

@Component({
  selector: 'app-car-image-processing',
  templateUrl: './car-image-processing.component.html',
  styleUrls: ['./car-image-processing.component.css']
})
export class CarImageProcessingComponent {
  hexImage: any;
  isLoading: boolean = false;
  imageUrls: string[];
  processError: any;

  carImageForm = new FormGroup({
    input_image: new FormControl(''),
    operation: new FormControl(''),
  });

  constructor(
    private route: ActivatedRoute,
    private imageProcessingService: ImageProcessingService
  ) {
    this.imageUrls = new Array();
    this.processError = false;
  }

  selectImage(event: any) {
    const selectedFile = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const arrayBuffer = e.target.result;
      const uint8Array = new Uint8Array(arrayBuffer);
      let hexString = '';
      for (let i = 0; i < uint8Array.length; i++) {
        const hex = uint8Array[i].toString(16).padStart(2, '0');
        hexString += hex;
      }
      this.hexImage = hexString;

      this.carImageForm.patchValue({
        input_image: hexString
      });
    };

    if (selectedFile) {
      reader.readAsArrayBuffer(selectedFile);
    }
  }

  onSubmit() {
    this.isLoading = true;
    const formData = new FormData();
    formData.append('input_image', this.hexImage);
    formData.append('operation', '1');
    this.processError = false;

    this.imageProcessingService.processCarImage(formData).subscribe(res => {
      if (res['image_data']) {
        const hex_image = res['image_data'].toString();

        // Crea un array di byte da dati esadecimali
        const bytes = [];
        for (let i = 0; i < hex_image.length; i += 2) {
          bytes.push(parseInt(hex_image.substr(i, 2), 16));
        }

        // Converti l'array di byte in un oggetto Uint8Array
        const byteArray = new Uint8Array(bytes);

        // Crea un blob dai dati Uint8Array
        const blob = new Blob([byteArray], { type: 'image/png' });

        // Genera un URL utilizzando createObjectURL
        const imageUrl = URL.createObjectURL(blob);

        // Assegna l'URL di immagine alla variabile del componente per visualizzarla nel template
        this.imageUrls.push(imageUrl);
      } else {
       this.processError = true; 
      }

      this.isLoading = false
    });
  }

  async createZipFile() {
    const zip = new JSZip();
  
    // Effettua richieste parallele per scaricare i dati delle immagini
    await Promise.all(this.imageUrls.map(async (imageUrl, index) => {
      try {
        const response = await fetch(imageUrl);
        const imageData = await response.blob();
        zip.file(`image_${index + 1}.png`, imageData);
      } catch (error) {
        console.error(`Errore nel caricamento dell'immagine ${index + 1}:`, error);
      }
    }));
  
    // Genera il file ZIP
    const zipContent = await zip.generateAsync({ type: 'blob' });
  
    // Salva il file ZIP
    const zipFilename = 'immagini.zip';
    const zipBlob = new Blob([zipContent], { type: 'application/zip' });
  
    // Crea un link temporaneo per il download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(zipBlob);
    link.download = zipFilename;
  
    // Aggiungi il link al DOM e avvia il download
    document.body.appendChild(link);
    link.click();
  
    // Rimuovi il link dal DOM dopo il download
    document.body.removeChild(link);
  }   

  cancel(): void {
    this.hexImage = null;
    this.carImageForm.patchValue({
      input_image: null
    });
  }

  downloadImages() {
    let index = 0;

    if(this.imageUrls.length == 1) {
      let imageUrl = this.imageUrls[0];

      // Crea un elemento <a> invisibile
      const link = document.createElement('a');
      link.style.display = 'none';

      // Imposta l'URL dell'immagine come attributo href dell'elemento <a>
      link.href = imageUrl;

      // Imposta il nome del file
      link.download = 'image_'+index+'.png';
      index++;

      // Aggiungi l'elemento <a> al DOM
      document.body.appendChild(link);

      // Simula il clic sull'elemento <a> per avviare il download
      link.click();

      // Rimuovi l'elemento <a> dal DOM dopo il download
      document.body.removeChild(link);
    } else {
      this.createZipFile();
    }
  }

  openModal(index: any): void {
    // Apri la modale
    console.log(index);
    const modalElement = document.querySelector('.modal');
    console.log(modalElement)
    if (modalElement) {
      console.log("SONO DENT")
      modalElement.classList.add('show');
      modalElement.setAttribute('aria-hidden', 'false');
    }
  }
}
