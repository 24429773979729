<div class="container mt-5">
  <div class="row">
    <div class="col-sm-10 d-flex align-items-center">
      <h1>Car Image Processing</h1>
    </div>
    <div class="col-sm-2 justify-content-center">
      <img class="card-img-top" [src]="'assets/blue_logo.png'" style="width:200px; object-fit: cover;">
    </div>
  </div>
  <div class="row">
    <router-outlet></router-outlet>
  </div>
</div>

