import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CarImageProcessingComponent } from './car-image-processing/car-image-processing.component';

const routes: Routes = [
  { path: '', component: CarImageProcessingComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
